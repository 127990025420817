<template>
  <div
    class="StepView">
    <div>
      <ProgressInstance
        v-if="isInstanceTakingTooLong"
        :instance-progress="computedInstanceProgress"
        key="step-view-progress-instance"
      />

      <component
        v-else
        :is="computedView"
        v-bind="currentStep.component?.props"
      />

      <ModalBase
        width="98%"
        :is-open="isTrapStudentModalOpen"
        @close="handleNoStudent"
      >
        <MaybeStudentForm
          @no="handleNoStudent"
          @yes="handleYesStudent"
        />
      </ModalBase>
    </div>
  </div>
</template>


<script>
import { defineAsyncComponent } from 'vue';
import useInstance from '@/use/instance';
import useTrapStudent from '@/use/trap-student';
import MaybeStudentForm from '@/forms/MaybeStudentForm.vue';
import ModalBase from '@/components/ModalBase.vue';
import ProgressInstance from '@/components/ProgressInstance.vue';

export default {
  name: 'StepView',

  components: {
    MaybeStudentForm,
    ModalBase,
    ProgressInstance,
  },

  inject: [
    'currentStep',
  ],

  setup() {
    const {
      launchAttempts,
      maxLaunchAttempts,
      isInstanceReady,
      isInstanceTakingTooLong,
    } = useInstance();

    const {
      isTrapStudentModalOpen,
      handleNoStudent,
      handleYesStudent,
    } = useTrapStudent();

    return {
      launchAttempts,
      maxLaunchAttempts,
      isInstanceReady,
      isInstanceTakingTooLong,

      isTrapStudentModalOpen,
      handleNoStudent,
      handleYesStudent,
    };
  },

  computed: {
    computedView() {
      if (!this.currentStep) return null;
      return defineAsyncComponent(() => import(`@/${this.currentStep.component.path}.vue`));
    },

    computedInstanceProgress() {
      return !this.isInstanceReady
        ? (this.launchAttempts / this.maxLaunchAttempts) * 100
        : 100;
    },
  },
};
</script>
